import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "amount",
    "selectedDonor",
    "campaignId",
    "campaignCommitments",
    "donationTypeSelect",
    "sponsorshipFieldsTemplate",
    "projectFieldsTemplate",
    "campaignFieldsTemplate",
    "donationTypeFields"
  ]

  connect() {
    this.displayDonationTypeFields()
    if(this.data.get('donationType') == 'Donations::CampaignDonation') {
      this.fetchCampaignCommitments()
    }
  }

  storeAutocompleteField() {
    window.donor_autocomplete = this.selectedDonorTarget
  }

  handleAmountChange(event) {
    event.target.setAttribute('value', event.target.value)
    this.checkIfSelectedCampaignCommitmentsCovered()
  }

  displayDonationTypeFields(event) {
    let selectedType = this.donationTypeSelectTarget.selectedOptions[0]
    switch(selectedType.value) {
      case "Donations::SponsorshipDonation":
        this.donationTypeFieldsTarget.innerHTML = this.sponsorshipFieldsTemplateTarget.innerHTML
        break
      case "Donations::ProjectDonation":
        this.donationTypeFieldsTarget.innerHTML = this.projectFieldsTemplateTarget.innerHTML
        break
      case "Donations::CampaignDonation":
        this.donationTypeFieldsTarget.innerHTML = this.campaignFieldsTemplateTarget.innerHTML
        break
      default:
        this.donationTypeFieldsTarget.innerHTML = ""
    }
  }

  handleDonorChange(event) {
    if(this.hasCampaignCommitmentsTarget) {
      this.fetchCampaignCommitments()
    }
  }

  handleCommitmentsChange(event) {
    this.checkIfSelectedCampaignCommitmentsCovered()
  }

  fetchCampaignCommitments() {
    if (!this.selectedDonorTarget.value || !this.campaignIdTarget.value) {
      this.campaignCommitmentsTarget.innerHTML = "Wähle eine/n Spender/in und eine Kampagne aus um offene Zahlungsverpflichtungen anzuzeigen."
      return
    }

    let queryParams = [
      `donor_id=${this.selectedDonorTarget.value}`,
      `campaign_id=${this.campaignIdTarget.value}`
    ]

    if(this.data.get('donationId')) {
      queryParams.push(`donation_id=${this.data.get('donationId')}`)
    }

    let queryString = queryParams.join('&')

    fetch(`/donations/commitments?${queryString}`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
    })
    .then((response) => response.text())
    .then((html) => {
      this.campaignCommitmentsTarget.innerHTML = html
    })
  }

  checkIfSelectedCampaignCommitmentsCovered() {
    let checked = this.campaignCommitmentsTarget.querySelectorAll("input[type='checkbox']:checked")
    let sum = 0
    checked.forEach((checkbox) => {
      sum += parseFloat(checkbox.getAttribute('data-amount'))
    })

    let error = this.campaignCommitmentsTarget.previousElementSibling

    if (sum > this.amountTarget.value) {
      if(!error) {
        this.campaignCommitmentsTarget.insertAdjacentHTML('beforebegin', `
          <div class="text-red mb-1">
            Die ausgewählten Zahlungsverpflichtungen übersteigen den Spendenbetrag.
          </div>
        `)
      }
    } else {
      if (error) {
        error.remove()
      }
    }
  }
}