import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'donationForm',
  ]

  initialize() {
    this.donationIndex = parseInt(this.data.get('donationIndex'))
    this.totalAmount = parseFloat(this.data.get('totalAmount'))
    this.nextDonationIndex = this.donationIndex + 1

    if(this.donationFormTargets.length > 1) {
      this.donationFormTargets.forEach((donationForm, index) => {
        donationForm.querySelector('button[data-action="bank-transaction-form#removeDonation"]').classList.remove('hidden')
      })
    }
  }

  addDonation(event) {
    event.preventDefault()
    this.lastDonationForm().insertAdjacentHTML('afterend', this.newDonationFormHTML())
    this.donationFormTargets.forEach((donationForm, index) => {
      donationForm.querySelector('button[data-action="bank-transaction-form#removeDonation"]').classList.remove('hidden')
    })
    this.nextDonationIndex++
  }

  removeDonation(event) {
    event.preventDefault()
    let button = event.target.closest('button') || event.target
    let donationForm = this.donationFormTargets[parseInt(button.getAttribute('data-index'))]
    donationForm.remove()
    this.donationFormTargets.forEach((donationForm, index) => {
      donationForm.querySelector('button[data-action="bank-transaction-form#removeDonation"]').setAttribute('data-index', index)
    })

    if(this.donationFormTargets.length == 1) {
      this.donationFormTarget.querySelector('button[data-action="bank-transaction-form#removeDonation"]').classList.add('hidden')
    }
    this.nextDonationIndex--
  }

  newDonationFormHTML() {
    let newDonationForm = this.lastDonationForm().cloneNode(true)

    // remove the input element with class 'form-control' which is the next sibling of input field with data-controller="datepicker"
    newDonationForm.querySelector('input[data-controller="datepicker"]').nextElementSibling.remove()

    newDonationForm.querySelector('input[data-target="autocomplete.input"]').value = this.donationFormTarget.querySelector('input[data-target="autocomplete.input"]').value
    newDonationForm.querySelector('input[id*="donation_is_deductible_"]').id = 'donation_is_deductible_' + Date.now()
    newDonationForm.querySelector('label[for*="donation_is_deductible_"]').setAttribute('for', 'donation_is_deductible_' + Date.now())
    newDonationForm.querySelector('input[id*="donation_is_not_deductible_"]').id = 'donation_is_not_deductible_' + Date.now()
    newDonationForm.querySelector('label[for*="donation_is_not_deductible_"]').setAttribute('for', 'donation_is_not_deductible_' + Date.now())

    let usedAmount = 0
    this.donationFormTargets.forEach((donationForm, index) => {
      usedAmount += parseFloat(donationForm.querySelector('input[data-target="donation-form.amount"]').value)
    })

    let formattedAmount = (this.totalAmount - usedAmount).toFixed(2);
    newDonationForm.querySelector('input[data-target="donation-form.amount"]').setAttribute('value', formattedAmount)

    let newDonationFormHTML = newDonationForm.outerHTML
    newDonationFormHTML = newDonationFormHTML.replace(/\[donations_attributes\]\[\d\]/g, `[donations_attributes][${this.nextDonationIndex}]`)
    newDonationFormHTML = newDonationFormHTML.replace(/donations_attributes_\d/g, `donations_attributes_${this.nextDonationIndex}`)
    newDonationFormHTML = newDonationFormHTML.replace(/data-index\=\"\d\"/g, `data-index="${this.nextDonationIndex}"`)

    return newDonationFormHTML
  }

  lastDonationForm() {
    return this.donationFormTargets[this.donationFormTargets.length - 1]
  }
}