export default class TrixCustomization {
  addColorButton() {
    const COLORS = {
      fgColor1: 'rgb(185, 94, 6)',
      fgColor2: 'rgb(207, 0, 0)',
      fgColor3: 'rgb(124, 58, 237)',
      fgColor4: 'rgb(5, 98, 185)',
      fgColor5: 'rgb(13, 135, 25)',
      fgColor6: 'rgb(148, 82, 22)',
    }

    document.addEventListener("trix-before-initialize", function (event) {
      Object.values(COLORS).forEach((color, i) => {
        Trix.config.textAttributes[`fgColor${(i + 1)}`] = { 
          style: { color: color }, 
          inheritable: true, 
          parser: e => e.style.color == color 
        }
      })
      Trix.config.textAttributes.frozen = {}
    }, true)
  }

  addPlaceholderButton() {
    document.addEventListener("trix-action-invoke", function(event) {
      const { target, invokingElement, actionName } = event
      if (actionName === "x-insert-placeholder") {
        target.editor.insertString(invokingElement.value)
      }
    })

    document.addEventListener('trix-selection-change', function (event) {
      const editor = event.target.editor

      var startOfSelection = editor.getSelectedRange()[0];
      var endOfSelection = editor.getSelectedRange()[1];

      if (startOfSelection == endOfSelection) {
        return
      }

      var documentString = editor.getDocument().toString()
      if ((documentString.charAt(startOfSelection - 1) == '{' && documentString.charAt(startOfSelection - 2) == '{') 
        && (documentString.charAt(endOfSelection) == '}' && documentString.charAt(endOfSelection + 1) == '}')) {
        editor.setSelectedRange([startOfSelection - 2, endOfSelection + 2])
      }
    })

    document.addEventListener("trix-toolbar-dialog-hide", function(event) {
      const { target, dialogName, explicitOriginalTarget } = event
      if(dialogName === "choose-placeholder") {
        if (explicitOriginalTarget.tagName === "BUTTON" && explicitOriginalTarget.dataset.trixAction === "x-insert-placeholder") {
          let placeholder = explicitOriginalTarget.value
          let [start, end] = target.editor.getSelectedRange()

          if (start == end) {
            target.editor.setSelectedRange([start + placeholder.length, end + placeholder.length])
          } else {
            target.editor.setSelectedRange([start, start + placeholder.length])
          }
        }
      }
    })
  }
}