import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "donorSelection",
    "donorDetails",
  ]

  connect() {
    if(this.donorSelectionTarget.value) {
      this.displayDetails()
    }
  }

  displayDetails() {
    let donorId = this.donorSelectionTarget.value
    fetch("/persons/previews/"+donorId)
      .then(response => response.text())
      .then(html => {
        this.donorDetailsTarget.innerHTML = html
      })
  }
}
